.filter {
	position: absolute;
	top: 3.75rem;
	width: 15.9rem;
	padding-block: 1.25rem;
	padding-inline: 1.6875rem;
	border-radius: 11px;

	box-shadow: var(--shadow);
	background-color: var(--color-background-header);

	display: flex;
	justify-content: space-around;
	flex-direction: row;

	font-family: 'Roboto', sans-serif;
	font-size: 1rem;
	font-weight: 500;
	color: var(--color-light-gray);
}

.filter_ico {
	width: 0.75rem;
	height: 0.75rem;
}

.filter_block {
	border-bottom: 0.0625rem solid var(--color-gray);
	margin-bottom: 1.25rem;
}

.filter_block:nth-of-type(1) {
	margin-bottom: 1rem;
}

.filter_block:nth-of-type(3) {
	height: auto;
}

.filter_block:nth-of-type(4) {
	width: auto;
}

.filter_item {
	display: flex;
	justify-content: start;
	align-items: center;
	flex-wrap: wrap;

	margin-bottom: 0.9375rem;
	> span {
		margin-bottom: 0.625rem;
		display: block;
		font-weight: 500;
		font-size: 0.82rem;
	}
}

button {
	border-style: none;
	cursor: pointer;
}

.filt_btn {
	width: 12.75rem;
	height: 1.75rem;

	background: none;

	border-radius: 0.4375rem;
	border: 0.0625rem solid var(--color-light-gray);

	font-size: 0.75rem;
	font-family: inherit;
	color: inherit;

	text-align: center;
	display: flex;
	align-items: center;
	justify-content: end;
}

.polygone {
	width: 0;
	height: 0;
	border-left: 0.25rem solid transparent;
	border-right: 0.25rem solid transparent;
	border-top: 0.375rem solid var(--color-light-gray);

	margin: 0 5% 0 25%;
}

.polygone::active {
	border-top: 0rem;
	border-bottom: 0.375rem solid var(--color-light-gray);
}

.reload_btn {
	background: none;

	display: flex;
	align-items: center;

	margin-left: 0.55rem;
}

.reload_btn:active img {
	animation: rotate 0.25s linear;
}

@keyframes rotate {
	to {
		transform: rotate(-360deg);
	}
}

.label_wrapper {
	display: flex;
	align-items: center;
	box-shadow: 1px 1px 10px 0px rgba(0, 0, 0, 0.15);

	border-radius: 7px;
	border: 1px solid var(--color-light-gray);

	width: 4.977rem;
	height: 1.55rem;
	box-sizing: border-box;
	span {
		font-size: 0.5625rem;
		margin: 0rem 0.45rem;
		color: var(--color-gray-filt);
	}
	input {
		font-size: 0.7rem;
	}
}

.label_wrapper:first-child {
	margin-right: 0.3rem;
}

input[type='number'] {
	-moz-appearance: textfield; /* Firefox */
	appearance: textfield; /* Chrome, Safari, Edge, Opera */
	font-size: 0.7rem;
	font-family: 'Roboto', sans-serif;
	font-weight: 400;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

.label_sum {
	width: 50%;
}

.filter_content {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.filter_block_radio {
	border-bottom: 0.0625rem solid var(--color-gray);
	margin-bottom: 1.25rem;
}

.wrapper_radio_btn {
	display: flex;
	flex-direction: row;
	gap: 8px;
	margin-bottom: 0.2875rem;
	width: auto;
}

.wrapper_radio_btn:last-child {
	margin-bottom: 1.125rem;
}

.label_radio {
	display: flex;
	align-items: center;
	width: 100%;
	flex-direction: row;
	cursor: pointer;
	font-size: 0.82rem;
	font-weight: 400;

	img {
		margin-left: 0.3125rem;
	}
	span {
		margin: 0;
	}
}

.wrapper_radio_btn input {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;

	border-radius: 0.1875rem;
	width: 0.9rem;
	height: 0.8rem;

	border: 0.0625rem solid var(--color-light-gray);
	transition: 0.2s all linear;
	outline: none;

	margin: 0rem;
	cursor: pointer;
}

.wrapper_radio_btn input:checked {
	background-color: var(--color-blue);
	border: var(--color-blue);
	background-image: url('../../assets/icons/check.svg');
	background-repeat: no-repeat;
	background-position: center;
}

.round {
	border-radius: 50%;
	height: 0.25rem;
	width: 0.25rem;
	background: var(--color-light-green);
	margin-left: 0.3125rem;
}

.btn_search {
	background: none;
	border-style: none;
	width: 100%;

	color: inherit;
	font-family: inherit;
	font-size: 0.875rem;

	padding: 0;
	margin-bottom: 0.1875rem;

	img {
		margin-right: 0.5rem;
	}

	display: flex;
	align-items: center;
}

.btn_search:last-child {
	margin-bottom: 1.125rem;
	img {
		margin-right: 0.65rem;
	}
}

.btn_clear {
	background: none;
	border-style: none;
	width: 100%;

	color: var(--color-light-red-two);
	font-family: 'Roboto', sans-serif;
	font-size: 0.875rem;

	padding-inline-start: 5px;

	img {
		margin-right: 0.6rem;
		width: 0.75rem;
		height: 0.75rem;
	}

	display: flex;
	align-items: center;
}
.imgIcon {
	width: 0.75rem;
	height: 0.75rem;
}
.imgLockIcon {
	width: 0.75rem;
	height: 0.75rem;
}
.imgVerifyIcon {
	width: 0.6875rem;
	height: 0.6875rem;
}
.imgLikedIcon {
	width: 1.0625rem;
	height: 1.0625rem;
}
.imgSaveIcon {
	padding-inline-start: 0.19rem;
	// width: 0.9375rem;
	// height: 0.9375rem;
}
.boldBlue {
	color: var(--color-blue);
}
.rubFilt {
	color: var(--color-lighter-green);
}
.tonFilt {
	color: var(--color-blue);
}
// .wrapperSwitchSpan {
// 	display: flex;
// 	justify-content: start;
// 	align-items: center;
// }
.switchRub {
	display: flex;
	justify-content: start;
	align-items: center;
	width: 1.65rem;
	height: 0.81rem;
	background: none;
	border-radius: 2.125rem;
	border: 0.125rem solid var(--color-light-green);
	margin-top: 0.1875rem;
	margin-bottom: 0.9rem;
	margin-left: 0.4rem;
	cursor: pointer;
}
.btnRoundRub {
	width: 0.4rem;
	height: 0.4rem;
	background-color: var(--color-light-green);
	border-radius: 50%;
	margin: 0rem 0.15rem;
	transition: 0.4s ease-in-out;
}
.switchTon {
	display: flex;
	justify-content: start;
	align-items: center;
	width: 1.65rem;
	height: 0.81rem;
	background: none;
	border-radius: 2.125rem;
	border: 0.125rem solid var(--color-blue);
	margin-top: 0.1875rem;
	margin-bottom: 0.9rem;
	margin-left: 0.4rem;
	cursor: pointer;
}
.btnRoundTon {
	width: 0.4rem;
	height: 0.4rem;
	background-color: var(--color-blue);
	border-radius: 50%;
	margin: 0rem 0.125rem;
	translate: 190%;
	transition: 0.4s ease-in-out;
}
.wrapper_search {
	border-bottom: 0.0625rem solid var(--color-gray);
	margin-bottom: 1.25rem;
}

.dash {
	width: 0.2188rem;
	height: 0.0938rem;
	background-color: var(--color-light-gray);
	margin-right: 0.3rem;
}
