.wrapperDefaultPrice {
	width: 100%;
	display: flex;
	justify-content: center;
	span {
		font-size: 4rem;
		font-weight: 600;
		font-family: 'Roboto', sans-serif;
		color: var(--color-price-default);
	}
}
.wrapperPriceType {
	display: flex;
	justify-content: center;
	width: 100%;
	.priceType {
		align-items: center;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		background-color: var(--color-price-type);
		height: 1.5625rem;
		width: 9.9375rem;
		border-radius: var(--l-raduis);
		padding-right: 0.1875rem;
		padding-left: 0.1875rem;
		.nonClickedButton {
			height: 1.3125rem;
			width: 2.8125rem;
			font-weight: 500;
			color: var(--color-light-gray);
			background: none;
			margin-right: 0.1875rem;
			font-size: 0.75rem;
			&:last-of-type {
				margin-right: 0;
			}
		}
		.clickedBtn {
			width: 2.875rem;
			background-color: #fff;
			border-radius: 0.375rem;
			height: 1.3125rem;
			font-weight: 500;
			color: #131314da;

			margin-right: 0.1875rem;
			font-size: 0.75rem;
			&:last-of-type {
				margin-right: 0;
			}
		}
	}
}

.wrapperRootPriceSwitcher {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	align-content: space-around;
	width: 24.1875rem;
	height: 17.1875rem;
	border-radius: var(--md-raduis);
	box-shadow: var(--shadow);
	margin-bottom: 1.6875rem;
}
.BtnBuy {
	background-color: var(--color-dark-blue);
	width: 15.1875rem;
	height: 2.8125rem;
	border-radius: var(--md-raduis);

	a {
		color: var(--color-white);
		font-size: 1.125rem;
		font-weight: 600;
	}
}
.wrapperByBtn {
	display: flex;
	justify-content: center;
	width: 100%;
}

.wrapperTitle {
	margin-top: 1.25rem;
	display: flex;
	justify-content: center;
	width: 100%;
	span {
		font-size: 1rem;
		font-weight: 600;
		color: var(--color-light-gray-secondary);
	}
}
.wrapperHotTitle {
	background: none;
	display: flex;
	justify-content: center;
	width: 100%;
	color: var(--color-gray-third);
	font-size: 0.625rem;
	font-weight: 500;
}
