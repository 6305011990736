.wrapperInfoAboutPublic {
	display: flex;
	justify-content: center;
	align-items: center;

	.PrivateLink {
		font-size: 0.875rem;
		font-weight: 500;
		color: var(--color-light-gray);
		span {
			color: var(--color-red);
		}
	}
	.OpenLink {
		font-size: 0.875rem;
		font-weight: 500;
		color: var(--color-light-gray);
		span {
			color: var(--color-dark-green);
		}
	}
}
