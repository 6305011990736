.blockStatistic {
	display: flex;
	flex-direction: column;
	text-align: center;
	align-items: center;
	margin-top: 1.5625rem;
}

.btnStat {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 11.125rem;
	height: 2.6875rem;
	border: 0.125rem solid var(--color-light-blue);
	border-radius: 0.9375rem;
	color: var(--color-light-blue);
	font-size: 1.125rem;
	font-weight: 700;
	margin-top: 1.5625rem;
}

.btnStat:nth-of-type(2) {
	border: 0.125rem solid var(--color-yellow);
	color: var(--color-yellow);
	margin-top: 0.625rem;
}

.btnStat:active {
	color: var(--color-white);
	background-color: var(--color-light-blue);
}

.btnStat:active img {
	filter: brightness(0) invert(1);
}

.btnStat:nth-of-type(2):active {
	background-color: var(--color-yellow);
}

.TgStatImg {
	width: 0.9375rem;
	height: 0.9375rem;
	margin-right: 0.4375rem;
}

.TelemetrImg {
	width: 1.375rem;
	height: 1.3125rem;
	margin-right: 0.25rem;
}
