.search {
	width: 21.9rem;
	height: 2.3rem;
	border-radius: 0.55rem;
	//background: var(--color-white-background);

	display: flex;
	justify-content: space-between;
	gap: 0.4rem;
	align-items: center;

	padding-inline: 0.875rem;
	border: 0.125rem solid var(--color-light-gray);

	box-shadow: var(--shadow-style);
	font-size: 0.8rem;
	color: var(--color-light-gray);
	font-family: 'Roboto', sans-serif;
}

input {
	width: 100%;
	border-style: none;
	font-family: 'Roboto', sans-serif;
	font-size: 0.875rem;
	font-weight: 400;
	outline: none;
}

input::placeholder {
	color: var(--color-light-gray);
	opacity: 1;
}
::-ms-input-placeholder {
	/* Edge 12 -18 */
	color: var(--color-light-gray);
}

.img_search {
	width: 0.85rem;
	height: 0.95rem;
}
