.wrapperLinkChannel {
	display: flex;
	justify-content: center;
	color: var(--color-dark-blue);
	background: none;
	font-size: 1rem;
	font-weight: 600;
	width: 19.375rem;
	height: 2.8125rem;
	align-items: center;
	font-family: 'Roboto', sans-serif;
	padding: 0.4375rem 0.625rem 0.4375rem 0.625rem;
	border-radius: 0.9375rem;
	border: 0.0625rem solid var(--color-dark-blue);
	box-shadow: var(--shadow);
	transition: all 0.5s ease-in-out;
	position: relative;

	&:hover {
		background-color: var(--color-dark-blue);
		color: var(--color-white);
	}

	a {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100%;
		height: 100%;
		text-decoration: none;
		color: inherit;
		position: relative;
	}
}

.visibleText,
.hiddenText {
	position: absolute;
	transition: opacity 0.5s ease-in-out;
}

.visibleText {
	opacity: 1;
}

.hiddenText {
	opacity: 0;
}
