.nightlyWrapperHeader {
	display: flex;
	justify-content: space-between;
	align-items: center;
	text-align: center;
	height: 5.125rem;
	align-content: center;
	padding-left: 7.1875rem;
	padding-right: 7.1875rem;
	box-shadow: var(--shadow-style);
	background-color: var(--color-background-header);
	div {
		display: flex;
		align-content: center;
		align-items: center;
		button {
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}
}
.wrapperHeader {
	display: flex;
	position: relative;
	justify-content: space-between;
	align-items: center;
	text-align: center;
	height: 3.6rem;
	width: 100%;
	align-content: center;
	padding-left: 3.8rem;
	padding-right: 3.8rem;
	box-shadow: var(--shadow-style);
	background-color: var(--color-background-header);

	.toPath {
		display: flex;
		align-items: center;
		img:nth-of-type(1) {
			width: 1.9rem;
			height: 1.9rem;
			margin-right: 2.5%;
		}
		img:nth-of-type(2) {
			width: 5rem;
			height: 2.6rem;
			margin-top: 0.3rem;
		}
	}

	div {
		display: flex;
		align-items: center;
		button {
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}
}
.LoginBtn {
	display: flex;
	background-color: var(--color-blue);
	color: var(--color-white);

	height: 100%;
	gap: 10px;
	padding-left: 0.755rem;
	padding-right: 1.225rem;
	border-radius: 1.25rem;
	font-weight: 700;
	font-size: 0.75rem;
	font-family: 'Roboto', sans-serif;
	img {
		width: 1.4rem;
		height: 1.4rem;
		border-radius: 50%;
	}
	a {
		display: flex;
		flex-direction: row;
		align-items: center;
	}
}
.catalogHeader {
	position: absolute;
	transform: translate(-45%, -50%);
	top: 50%;
	left: 50%;

	button {
		display: flex;
		flex-direction: column;
		justify-content: space-between !important;
		width: 7.5625rem;
		height: 2.625rem;
		&:nth-of-type(1) {
			margin-right: 3.4rem;
		}
		span {
			color: var(--color-light-gray);
			font-family: 'Roboto', sans-serif;
			font-size: 0.75rem;
			font-weight: 600;
			margin-top: 16%;
		}
		.underline {
			width: 100%;
			height: 0.2rem;
			background-color: var(--color-blue);
			border-top-left-radius: 0.4375rem;
			border-top-right-radius: 0.4375rem;
		}
		.underlineYellow {
			width: 100%;
			height: 0.2rem;
			background-color: var(--color-yellow-new);
			border-top-left-radius: 0.4375rem;
			border-top-right-radius: 0.4375rem;
		}
	}
	.clickedHotBtn {
		width: 7.5625rem;
		height: 3.6rem;
		span {
			color: #ffc700;
		}
		&:hover {
			background-color: var(--color-yellow-header);
		}
	}
	.nonClickedHotBtn {
		width: 7.5625rem;
		height: 3.6rem;
		background-color: var(--color-background-header);
		span {
			color: #ffc700;
		}
		&:hover {
			background-color: var(--color-yellow-header);
		}
	}
	.clickedCatalogBtn {
		width: 7.5625rem;
		height: 3.6rem;
		span {
			color: #058ff9;
		}
		&:hover {
			background-color: var(--color-blue-header);
		}
	}
	.nonClickedCatalogBtn {
		width: 7.5625rem;
		height: 3.6rem;
		background-color: var(--color-background-header);
		span {
			color: #058ff9;
		}
		&:hover {
			background-color: var(--color-blue-header);
		}
	}
}

.lastWrapperInHeader {
	height: 2.15rem;
	gap: 25px;
	justify-content: space-between;
}
