.wrapperChannelComponent {
	display: flex;

	height: 23.1rem;
	// height: 25.125rem;
	width: 15.91rem;
	margin-right: 1.8rem;
	margin-top: 1.5rem;
	border-radius: var(--md-raduis);
	position: relative;
	box-shadow: var(--shadow-style);
	background-color: var(--color-background-header);
	overflow: hidden;
	.wrapperDescChannel {
		position: absolute;
		left: 50%;
		transform: translate(-50%, -50%);

		top: 10rem;
	}
	&:nth-child(4n) {
		margin-right: 0rem;
	}
}
.wrapperComponent {
	display: flex;
	justify-content: center;
	&:hover {
		.wrapperFavorite {
			display: block;
			position: absolute;
			left: 91%;
			top: 10%;
			margin-top: 0.1rem;
			border-radius: 0.3125rem;
		}
	}
}
.wrapperImg {
	display: flex;
	justify-content: center;

	.wrapperFavorite {
		display: none;
	}

	.wrapperImgAndDesc {
		top: 8%;
		left: 3%;
		display: flex;
		position: absolute;
		align-items: center;
		img {
			object-fit: cover;
			border: 0.3rem solid var(--color-background-header);
			border-radius: 50%;
			width: 4.5rem;
			height: 4.5rem;
			display: inline-block;
			margin-right: 0.25rem;
		}
	}
}
:root {
	h2 {
		color: var(--color-black);
		font-family: 'Roboto', sans-serif;
		font-size: 1.25rem;
	}
	.bordedElement {
		// border: 0.1rem solid var(--color-light-gray);
		background: rgba(0, 165, 233, 1);
		color: var(--color-white);
		border-radius: 3px;
		font-family: 'Roboto', sans-serif;
		// padding: 0rem 0.3125rem 0rem 0.4375rem;
		padding: 0.2rem 0.4125rem;
		font-size: 0.65rem;
		font-weight: 500;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		display: inline-block;
		// &:first-of-type {
		// 	margin-right: 0.3125rem;
		// }
	}
	.wrapperQuotes {
		// background-color: var(--color-quotes);
		display: flex;
		// flex-wrap: wrap;
		justify-content: start;
		align-items: center;
		text-align: start;
		// border-bottom-left-radius: var(--md-raduis);
		// border-top-left-radius: var(--md-raduis);
		// border-left: .1875rem solid var(--color-light-blue);
		height: 2.78rem;
		width: 13.34rem;
		//overflow: scroll;
	}
	.borderLeft {
		width: 0.19rem;
		height: 100%;
		background-color: var(--color-blue);
		border-radius: var(--md-raduis);
		margin-right: 0.36rem;
		flex-shrink: 0;
		box-shadow: 1px 1px 10px 0px rgba(0, 0, 0, 0.15);
	}
	.quotesStyle {
		quotes: initial;
		font-size: 0.725rem;
		font-weight: 400;
		font-family: 'Roboto', sans-serif;
	}
}
.wrapperStatistics {
	margin-top: 0.8125rem;
	display: flex;
	// width: 13.125rem;
	justify-content: space-between;
	flex-wrap: nowrap;
	div {
		display: flex;
		flex-direction: column;

		div {
			display: flex;
			justify-content: flex-start;
			flex-direction: row;
			align-items: center;

			span {
				font-size: 0.7245rem;
				font-weight: 400;
				strong {
					font-weight: 700;
				}
			}
		}
	}
}
.pairStatistics {
	display: flex;
	justify-content: center;
	align-items: start;
	border: 1px solid var(--color-gray-filt);
	border-radius: 5px;
	width: 9.855rem;
	height: 2.62rem;
	box-shadow: 1px 1px 10px 0px rgba(0, 0, 0, 0.15);
	&:last-child {
		text-wrap: wrap;
		text-align: center;
		align-items: center;
		width: 3.49rem;
		margin-left: 0.5rem;
	}
}

.pairStatistics2 {
	display: flex;
	justify-content: center;
	align-items: start;
	border: 1px solid var(--color-gray-filt);
	border-radius: 5px;
	width: 9.855rem;
	height: 2.62rem;
	box-shadow: 1px 1px 10px 0px rgba(0, 0, 0, 0.15);
	&:last-child {
		text-wrap: wrap;
		text-align: center;
		align-items: center;
		width: 3.49rem;
		margin-left: 0.5rem;
	}
	> div span {
		display: flex;
		flex-direction: column;
		gap: 0.25rem;
		font-size: 0.7245rem;
		font-weight: 400;
		strong {
			font-weight: 700;
		}
	}
}

.forFutureFeatures {
	position: absolute;
	top: 14.5625rem;
	left: 50%;
	transform: translate(-50%, -50%);

	display: flex;
	div {
		margin-right: 0.625rem;
		width: 3.125rem;
		height: 1.1875rem;
		border-radius: var(--md-raduis);
		background-color: var(--color-quotes);
	}
}
hr {
	position: absolute;
	top: 16rem;
	width: 12.875rem;
}
.wrapperDefaultPrice {
	height: 1.25rem;
	position: absolute;
	top: 14.6rem;
	span {
		height: 1.25rem;
		display: flex;
		font-size: 1.85rem;
		align-items: center;
		font-weight: 400;
		font-family: 'Roboto', sans-serif;
		color: var(--color-light-gray);
		img {
			margin-left: 0.5rem;
			width: 1.7rem;
			height: 1.7rem;
		}
	}
}
.priceType {
	position: absolute;
	top: 18.1rem;
	left: 50%;
	transform: translate(-50%, -50%);

	align-items: center;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	background-color: var(--color-darkness);
	height: 1.25rem;
	width: 9rem;
	// border-radius: var(--l-raduis);
	border-radius: 0.3rem;
	padding: 3px;
	.nonClickedButton {
		height: 1.05rem;
		width: 2.8125rem;
		font-weight: 500;
		color: var(--color-gray-filt);
		background: none;
		margin-right: 0.1875rem;
		font-size: 0.625rem;
		font-family: 'Roboto', sans-serif;
		&:last-of-type {
			margin-right: 0;
		}
	}
	.clickedBtn {
		height: 1.05rem;
		width: 2.875rem;
		background-color: var(--color-background-header);
		border-radius: 0.2rem;
		font-weight: 500;
		color: var(--color-light-gray);

		margin-right: 0.1875rem;
		font-size: 0.625rem;
		font-family: 'Roboto', sans-serif;
		&:last-of-type {
			margin-right: 0;
		}
	}
}
.BtnBuy {
	position: absolute;
	top: 20.8rem;
	left: 50%;
	background-color: var(--color-blue);
	width: 13.86rem;
	height: 2.06rem;
	border-radius: var(--md-raduis);
	transform: translate(-50%, -50%);
	color: var(--color-white);
	font-family: 'Roboto', sans-serif;
	font-size: 0.75rem;
	font-weight: 700;
	a {
		font-size: 0.83rem;
		font-weight: 600;
	}
	&:active {
		background-color: var(--color-blue-click);
	}
	&:hover {
		background: rgba(40, 162, 255, 1);
	}
}
.BtnFollowing {
	font-weight: 400;
	font-size: 0.5625rem;
	text-align: center;
	position: absolute;

	top: 24.125rem;
	left: 50%;
	width: 19.375rem;
	transform: translate(-50%, -50%);
	background: none;
	font-family: 'Roboto', sans-serif;
}
.borderRadius {
	border-top-left-radius: 11px;
	border-top-right-radius: 11px;
	object-fit: cover;
	width: 15.91rem;
	height: 3.8rem;
}
.wrapperImageIcon {
	display: block;
	margin-right: 0.35rem;
	margin-left: 0.4rem;
	width: 0.6rem;
	height: 0.9375rem;
}
.wrapperNameAndPosition {
	.linkStyle {
		font-size: 1.25rem;
		color: rgba(153, 168, 178, 1);
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		display: inline-block;
		max-width: 10rem;
		font-weight: 700;
	}
}
.wrapperFavoriteImg {
	width: 1.125rem;
	height: 1.0625rem;
}
.wrapperBLOCK {
	display: flex;
	justify-content: center;
	flex-direction: column;
}
.wrapperCategoryAndPosition {
	// margin-top: 0.225rem;
	margin-bottom: 0.65rem;
	display: flex;
	width: 7rem;
	justify-content: start;
	align-items: center;
	text-align: center;
}
