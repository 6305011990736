.wrapperButton {
	display: flex;
	background: none;
	color: var(--color-light-gray);
	border: 0.0625rem solid var(--color-light-gray);
	border-radius: var(--md-raduis);
	box-shadow: var(--shadow);
	width: 15.1875rem;
	height: 2.75rem;
	justify-content: center;
	align-items: center;
	font-size: 1rem;
	font-weight: 500;
}
