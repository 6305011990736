.wrapperNotFound {
    width: 34.375rem;
    height: 21.875rem;
    border-radius: var(--md-raduis);
    box-shadow: var(--shadow);
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    color: var(--color-price-default);
    background-color: var(--color-white-background);
}

.titleNotFound {
    font-weight: bold;
    font-size: 1.75rem;
    margin-bottom: 1.25rem;
}

.textNotFound {
    font-size: .875rem;
    color: var(--color-light-gray);
}