.wrapperRootDropDown {
	display: flex;
	align-items: center;
	gap: 0.725rem;
	.blockArrowImg {
		display: block;

		width: 1.1875rem;
		height: 0.8125rem;
	}
	// .rotatedArrowImg {
	//     transform: rotate(180deg);

	//     width: 1.5rem;
	//     height: 1.5rem;

	//     display: block;
	//     margin-top: .4rem;
	//     margin-right: .3125rem;
	// }
}
.wrapperDropDown {
	display: flex;
	flex-direction: column;
	align-items: center;
	// position: absolute;
	// // left: 78.4%;
	// left: 80.5rem;
	cursor: pointer;
	button {
		position: relative;
		background: none;
		border: 0.125rem solid var(--color-light-gray);
		border-radius: var(--md-raduis);
		color: var(--color-light-gray);
		display: flex;
		align-items: center;
		justify-content: center;
		width: 12.47rem;
		height: 2.25rem;
		font-size: 0.8rem;
		font-weight: 400;
		font-family: 'Roboto', sans-serif;
		box-shadow: var(--shadow-style);
	}
	ul {
		position: absolute;
		list-style-type: none;
		padding: 0;
		width: 12.5rem;
		z-index: 100;
		top: 8rem;
		background-color: var(--color-background-header);
		text-align: center;
		border-radius: var(--md-raduis);
		border: 0.125rem solid var(--color-light-gray);
		overflow: hidden;
		box-shadow: var(--shadow-style);
		height: 14.571rem;
		li {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 100%;
			height: 2.053rem;
			font-size: 0.75rem;
			font-weight: 400;

			padding-top: 0.3125rem;
			padding-bottom: 0.3125rem;
			font-family: 'Roboto', sans-serif;
			border-bottom: 0.2px solid rgba(153, 168, 178, 0.3);
			&:hover {
				background-color: var(--color-dark-blue) !important;
				color: var(--color-white) !important;
			}
			&:last-of-type {
				border-bottom: none;
			}
		}
		.clickedLi {
			background-color: var(--color-dark-blue);
		}
		.selected {
			background-color: #00a5e9;
			color: #fff;
		}
	}
}
.polygonDropDown {
	position: absolute;
	right: 1rem;
	width: 0;
	height: 0;
	border-left: 0.26rem solid transparent;
	border-right: 0.26rem solid transparent;
	border-bottom: 0.5rem solid #99a8b2;
}
.polygonDropDownRotate {
	position: absolute;
	right: 1rem;
	width: 0;
	height: 0;
	border-left: 0.26rem solid transparent;
	border-right: 0.26rem solid transparent;
	border-top: 0.5rem solid #99a8b2;
	border-bottom: none;
}

.btnSort {
	display: flex;
	align-items: center;
	justify-content: space-between;
}
