.wrapperBtnMyChannel {
	display: flex;
	justify-content: center;
	color: var(--color-blue);
	background: none;
	font-size: .75rem;
	font-weight: 600;
	width: 15.9rem;
	height: 2.25rem;
	align-items: center;
	font-family: 'Roboto', sans-serif;
	padding: 0.4375rem 0.625rem 0.4375rem 0.625rem;
	border-radius: 0.6375rem;
	border: 0.0625rem solid var(--color-blue);
	box-shadow: var(--shadow-style);
	color: var(--color-white);
	background-color: var(--color-blue);
	cursor: pointer;
	// &:active {
	// 	background-color: var(--color-blue-click);
	// 	border: 0.0625rem solid var(--color-blue-click);
	// }
	&:hover {
		background-color: var(--color-blue-click);
		border: 0.0625rem solid var(--color-blue-click);
	}
}
.wrapperPopupAddBtn {
	position: fixed;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;

	top: 50%;
	left: 50%;
	z-index: 999999;
	transform: translate(-50%, -50%);
	background-color: rgba(37, 37, 37, 0.7);
	.titleBlockAddBtn {
		display: flex;
		justify-content: center;
		align-items: center;
		text-align: center;
		flex-direction: row;
		position: relative;
		height: 1.875rem;
		width: 28.375rem;

		div {
			height: 100%;
			display: flex;
			align-items: center;
			span {
				display: block;
				font-size: 1.25rem;
				font-weight: 900;
				color: var(--color-white);
			}
		}
		.closeBtn {
			position: absolute;
			left: 85%;
			width: 1.1rem;
			height: 1.1rem;
			background-size: cover;
			display: block;
			background-image: url('../../../assets/close_icon.svg');
			background-repeat: no-repeat;
		}
	}
}
.wrapperAddChannelBtn {
	margin-top: 0.5rem;
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
	width: 22.7rem;
	height: 16.6rem;
	background-color: var(--color-background-header);
	border-radius: 0.9375rem;

	span {
		display: block;
		font-weight: 600;
		font-size: 0.75rem;
	}
	.first {
		margin-top: 0.9375rem;
	}

	.titlePopup {
		color: var(--color-white);
	}
}

.wrapperAddChannelBtnOne {
	margin-top: 1.875rem;
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
	width: 22.7rem;
	height: 9.2rem;
	background-color: var(--color-background-header);
	border-radius: 0.9375rem;

	span {
		display: block;
		font-weight: 600;
		font-size: 0.75rem;
	}

	.titlePopup {
		color: var(--color-white);
	}
}

.lineHor {
	width: 16.9rem;
	height: 0.06rem;
	background-color: var(--color-price-type);
	margin: 0.9375rem 0rem;
}

.contentAddChannel {
	display: flex;
	justify-content: center;
	flex-direction: column;
	width: 16.9rem;

	.labelCopyLink {
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 13rem;
		height: 2rem;
		border: 0.125rem solid var(--color-blue);
		border-radius: 0.55rem;
		overflow: hidden;
		margin-top: .5rem;
		span {
			font-weight: bold;
			margin-left: 0.9375rem;
			color: var(--color-blue);
		}
		&:hover {
			background-color: var(--color-light-blue-copy);
		}
	}
	.labelCopied {
		color: var(--color-white);
		background-color: var(--color-blue);
		display: flex;
		justify-content: center;
		align-items: center;
		width: 13rem;
		height: 2rem;
		border-radius: 0.55rem;
		margin-top: .5rem;
		transition: background-color 1s ease-in-out; 
		span {
			font-weight: bold;
			margin-left: 0rem;
			color: var(--color-white);
		}
		button {
			display: none;
		}
	}
}

.btnAdd {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 19.8rem;
	height: 2.3rem;
	border-radius: var(--md-raduis);
	background-color: var(--color-blue);
	color: var(--color-white);
	font-weight: bold;
	margin-top: 0.7rem;
	margin-bottom: 0.9375rem;
	user-select: none;
	font-size: 0.85rem;
	&:active {
		background-color: var(--color-blue-click);
	}
}

.verticalLine {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-right: 1rem;
	margin-bottom: 1.2rem;
	.lineV {
		background-color: var(--color-price-type);
		width: 0.05rem;
		height: 1.25rem;
		&:nth-of-type(3) {
			height: 4.9rem;
		}
		&:nth-of-type(5) {
			height: 4.05rem;
		}
	}
	.roundV {
		width: 0.25rem;
		height: 0.25rem;
		background-color: var(--color-blue);
		border-radius: 50%;
		margin: 0.0625rem;
	}
}

.verticalLineOne {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-top: 1.2rem;
	margin-right: 1rem;
	.lineV {
		background-color: var(--color-price-type);
		width: 0.05rem;
		height: 2.4rem;
		&:nth-of-type(6) {
			height: 2.05rem;
		}
	}
	.roundVOne {
		width: 0.25rem;
		height: 0.25rem;
		background-color: var(--color-dark-green);
		border-radius: 50%;
		margin: 0.0625rem;
		&:nth-of-type(1) {
			background-color: var(--color-blue);
		}
	}
}
.popupContent {
	display: flex;
	flex-direction: row;
}
.spanBlue {
	color: var(--color-blue);
}
.spanBlock {
	display: block;
}