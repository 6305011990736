.wrapperBtnChannel {
	display: flex;
	justify-content: center;
	font-size: 0.8rem;
	font-weight: 700;
	width: 15.9rem;
	height: 2.25rem;
	align-items: center;
	font-family: 'Roboto', sans-serif;
	padding: 0.4375rem 0.625rem 0.4375rem 0.625rem;
	border-radius: 11px;
	//border: 0.0625rem solid var(--color-dark-blue);
	box-shadow: 1px 1px 10px 1px rgba(0, 0, 0, 0.15);
	color: #ffffff;
	background: rgba(5, 143, 249, 1);
	cursor: pointer;
	&:hover {
		background: rgba(40, 162, 255, 1);
	}
}
