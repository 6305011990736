.wrapperRootDropDown {
	display: flex;
	align-items: flex-start;
	align-items: center;
}
.wrapperDropDown {
	display: flex;
	flex-direction: column;
	align-items: center;
	cursor: pointer;
	position: relative;

	input {
		display: block;
		width: 12.75rem;
		height: 1.75rem;

		background: none;

		border-radius: 0.4375rem;
		border: 0.0625rem solid var(--color-light-gray);

		font-size: 0.75rem;
		font-family: inherit;
		color: inherit;

		padding: 0.3125rem 0.9375rem 0.3125rem 3.125rem;
	}

	ul {
		list-style-type: none;
		padding: 0;
		background-color: var(--color-white-background);
		text-align: center;
		border-radius: 0.4375rem;
		border: 0.0625rem solid var(--color-light-gray);
		overflow: scroll;
		box-shadow: var(--shadow);
		position: absolute;
		z-index: 100;
		top: 2.1875rem;
		height: 9.375rem;

		li {
			width: 12.75rem;
			font-size: 0.875rem;
			font-weight: 500;

			padding-top: 0.3125rem;
			padding-bottom: 0.3125rem;
			font-family: 'Roboto', sans-serif;
			border-bottom: 0.0125rem solid var(--color-bottom-list);
			&:last-of-type {
				border-bottom: none;
			}
		}
		.clickedLi {
			background-color: var(--color-dark-blue);
		}
	}
}

.wrapperPolygon {
	position: absolute;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	right: 0;
	width: 1.3rem;
	height: 1.1rem;
	// border-top-right-radius: 0.4rem;
	// border-bottom-right-radius: 0.4rem;
	// border-right: 1.5px solid #99a8b2;
	// border-bottom: 1.5px solid #99a8b2;
	background-color: var(--color-background-header);
}

.wrapperBlockFlex {
	position: relative;
	left: -14%;
	display: flex;
	flex-wrap: wrap;
	width: 0.625rem;
}

.block {
	width: 0.32rem;
	height: 0.3rem;
	transform: rotate(45deg);
	background-color: var(--color-background-header);
}

.block:first-child {
	width: 0.31rem;
	height: 0.2rem;
}

.block:last-child {
	width: 0.31rem;
	height: 0.2rem;
}

.polygonDropDown {
	position: relative;
	left: -20%;
	border-left: 0.25rem solid transparent;
	border-right: 0.25rem solid transparent;
	border-bottom: 0.375rem solid #99a8b2;
}
.polygonDropDownRotate {
	position: relative;
	left: -20%;
	border-left: 0.25rem solid transparent;
	border-right: 0.25rem solid transparent;
	border-top: 0.375rem solid #99a8b2;
	border-bottom: none;
}

.btnSort {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.reload_btn {
	background: none;

	display: flex;
	align-items: center;

	margin-left: 0.3125rem;
}

.reload_btn:active img {
	animation: rotate 0.25s linear;
}

@keyframes rotate {
	to {
		transform: rotate(-360deg);
	}
}
.imgIcon {
	width: 0.75rem;
	height: 0.75rem;
}
