html,
body {
	width: 100%;
	height: 100%;
}

.footerWrap {
	width: 100%;
	background-color: var(--color-background-header);
	top: 100vh;

	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;

	align-content: center;
	font-weight: bold;
	box-shadow: var(--shadow-style);
}

.footerWrapper {
	font-size: 0.845rem;
	color: var(--color-light-gray);

	display: flex;
	flex-direction: column;

	margin: 3.4375rem 3.8rem;
}

.footerWrapper:nth-of-type(2) {
	justify-content: center;
	align-items: center;

	margin: 3.4375rem 0rem;

	.links {
		align-items: center;
	}
}

.links {
	display: flex;
	flex-direction: column;
	a {
		margin-bottom: 5%;
	}
}

.titleFooter {
	color: var(--color-dark-blue);

	margin-bottom: 3.125rem;
}

.links span {
	margin-top: 10%;
}

@media (width <= 67.5rem) {
	.footerWrapper {
		font-size: 0.875rem;

		margin: 3.4375rem 3.4375rem;
	}
}

@media (width <= 49.375rem) {
	.footerWrapper {
		font-size: 0.75rem;

		margin: 3.4375rem 2.5rem;
	}
}

@media (width <= 37.5rem) {
	body {
		height: auto;
	}
	footer {
		flex-direction: column-reverse;
		justify-content: center;
		align-items: center;
	}
	.footerWrapper {
		justify-content: center;
		align-items: center;

		margin: 1.25rem 0rem;
		.links {
			align-items: center;
		}
	}
	.footerWrapper:nth-of-type(2) {
		margin: 1.25rem 0rem;
	}
	.titleFooter {
		margin-bottom: 1.5625rem;
	}
	.links span {
		margin-top: 0.625rem;
	}
}
