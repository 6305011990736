.wrapperDefaultPriceUnderline {
	/* position: absolute;
	left: 60%;
	top: -40%; */
	display: flex;
	justify-content: center;
	width: 100%;
	.wrapperDiscPrice {
		font-size: 1rem;
		font-weight: 600;
		text-decoration: line-through;
		color: var(--color-light-gray);
	}
}
.wrapperDefaultPrice {
	display: flex;
	justify-content: center;
	width: 100%;
	position: relative;
	span {
		font-size: 4rem;
		font-weight: 600;
		font-family: 'Roboto', sans-serif;
		color: var(--color-price-default);
	}
}
.wrapperTypePrice {
	width: 100%;
	display: flex;
	justify-content: center;
	.priceType {
		align-items: center;
		display: flex;
		flex-direction: row;
		justify-content: center;
		//background-color: var(--color-price-type);
		height: 1.5625rem;

		border-radius: var(--l-raduis);
		padding-right: 0.1875rem;
		padding-left: 0.1875rem;

		.clickedBtn {
			display: flex;
			align-items: center;

			height: 1.25rem;
			background: none;
			border: 0.0625rem solid #d9d9d9;
			border-radius: 0.375rem;

			padding: 0.25rem;

			//margin-right: .1875rem;
			span {
				font-size: 0.625rem;
				color: var(--color-light-gray);
				font-weight: 500;
			}
		}
	}
}
.wrapperTitleHot {
	display: flex;
	justify-content: center;
	width: 100%;
	span {
		font-size: 1rem;
		font-weight: 600;
		color: var(--color-dark-yellow);
	}
}

.TimeBuy {
	display: flex;
	justify-content: space-evenly;
	align-items: center;
	margin-left: 0.625rem;
	width: 8.5rem;
	height: 0.9375rem;
	background-color: var(--color-red);
	font-size: 0.75rem;
	font-weight: 600;
	border-radius: 0.625rem;
	padding-top: 0.0625rem;
	padding-bottom: 0.0625rem;
	padding-left: 0.125rem;
	padding-right: 0.125rem;

	color: var(--color-white);
}
.wrapperRootHotPrice {
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	width: 24.1875rem;
	height: 17.1875rem;
	border: 0.1875rem solid var(--color-dark-yellow);
	border-radius: var(--md-raduis);
	box-shadow: var(--shadow-yellow);
	margin-bottom: 2.1875rem;
}
.wrapperBtnHot {
	display: flex;
	justify-content: center;

	width: 100%;
}
.BtnBuy {
	background-color: var(--color-dark-yellow);
	width: 15.1875rem;
	height: 2.8125rem;
	border-radius: var(--md-raduis);

	a {
		color: var(--color-white);
		font-size: 1.125rem;
		font-weight: 600;
	}
}
.TimeAndDiscount {
	height: 0.9375rem;
	display: flex;
	font-size: 0.75rem;
	font-weight: 600;

	justify-content: space-between;
	text-align: center;
	align-items: center;
	color: var(--color-white);
	.wrapperFormulaDis {
		width: 2.75rem;
		height: 0.9375rem;
		border-radius: 0.625rem;
		padding-top: 0.0625rem;
		padding-bottom: 0.0625rem;
		padding-left: 0.125rem;
		padding-right: 0.125rem;
		background-color: var(--color-red);
		font-size: 0.75rem;
		font-weight: 600;

		color: var(--color-white);
	}
}
.wrapperDiscountTime {
	display: flex;
	justify-content: center;
	width: 100%;
}
.wrapperNotHotPrice {
	width: 24.1875rem;
	height: 17.1875rem;
	border-radius: 0.9375rem;
	padding-left: 1.25rem;
	padding-right: 1.25rem;
	display: flex;
	align-items: center;
	text-align: center;
	justify-content: center;
	box-shadow: var(--shadow);
	background-color: var(--color-white-background);
	span {
		font-weight: 900;
		font-size: 1rem;
		color: var(--color-light-gray);
	}
}
