.wrapperPaginationButton {
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	//background: none;
	width: 8.95rem;
	height: 2.25rem;
	font-size: 0.775rem;
	font-weight: 700;
	margin: 0 auto;
	margin-top: 1.6875rem;
	margin-bottom: 1.6875rem;
	//border: 0.0625rem solid var(--color-light-gray);
	font-family: 'Roboto', sans-serif;
	border-radius: 11px;
	box-shadow: var(--shadow);
	background-color: var(--color-blue);
	color: var(--color-white);
}
