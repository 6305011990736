.wrapperPopupAuth {
	position: fixed;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	flex-direction: column;

	top: 50%;
	left: 50%;
	z-index: 999999;
	transform: translate(-50%, -50%);
	background-color: rgba(37, 37, 37, 0.7);
	.titleBlockAuth {
		display: flex;
		justify-content: center;
		align-items: center;
		text-align: center;
		flex-direction: row;
		position: relative;
		height: 1.875rem;
		width: 28.375rem;
		div {
			height: 100%;

			span {
				display: block;
				font-size: 1.375rem;
				font-weight: 900;
				color: var(--color-white);
			}
		}
		.closeBtn {
			position: absolute;
			left: 95%;
			width: 1.125rem;
			height: 1.125rem;
			background-size: cover;
			display: block;
			background-image: url('../../assets/close_icon.svg');
			background-repeat: no-repeat;
		}
	}
	a {
		color: var(--color-white);
	}
	// filter: alpha(opacity=60);
	//   /* IE */
	//   -moz-opacity: 0.6;
	//   /* Mozilla */
	//   opacity: 0.6;
	//   /* CSS3 */
}

.wrapperPopupLoginBtn {
	margin-top: 1.875rem;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	flex-direction: column;
	width: 28.5rem;
	height: 10.3125rem;
	background-color: var(--color-background-header);
	border-radius: .9375rem;
	padding: 0rem 4rem;
	a {
		display: flex;
		align-items: center;
		margin-top: 1.25rem;
		margin-bottom: 1.25rem;
		border-radius: 1.875rem;
		padding: .6rem .7rem;
		font-weight: 600;
		background-color: var(--color-blue);
		img {
			width: 1.1875rem;
			margin-right: .3125rem;
		}
		span {
			font-size: .875rem;
		}
		&:hover {
			background-color: var(--color-blue-click);
		}
	}

	span {
		display: block;

		color: var(--color-white);
		&:first-of-type {
			color: var(--color-light-gray);
			font-size: 1rem;
			font-weight: 600;
		}
		&:last-of-type {
			color: var(--color-light-gray-auth);
			font-size: .6rem;
			font-weight: 600;
		}
	}
}
