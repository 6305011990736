.wrapperQuotes {
	background-color: var(--color-quotes-two);
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;
	align-items: center;
	text-align: center;
	border-bottom-right-radius: var(--md-raduis);
	border-top-right-radius: var(--md-raduis);
	border-left: 0.125rem solid var(--color-light-blue);
	height: 11.875rem;
	width: 31.9375rem;
	position: relative;
}
.ownerBlock {
	position: absolute;
	top: 80%;
	left: 78%;
	display: flex;
	span {
		font-size: 0.75rem;
		font-weight: 600;
	}
	img {
		margin-left: 0.3125rem;
	}
}
.quotesStyle {
	display: block;
	margin-left: 0.625rem;
	width: 15.625rem;
	line-height: 1.25rem;
	text-align: left;
	quotes: initial;
	font-size: 1.125rem;
	font-weight: 500;
	font-family: 'Roboto', sans-serif;
}
