.wrapperMyChannel {
	display: flex;
	flex-direction: column-reverse;
}

.myChannelContent {
	display: flex;
}
.btnAddChannel {
	display: flex;
	justify-content: center;
}

.leftPanel {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-top: 1.6rem;
}

.titleBlock {
	font-size: 0.825rem;
	color: var(--color-dark-gray);
	font-weight: 800;
	margin-bottom: 1.1rem;
	&:nth-of-type(1) {
		color: var(--color-blue);
	}
	&:nth-of-type(2) {
		color: var(--color-dark-green);
	}
	&:nth-of-type(3) {
		color: var(--color-red);
	}
}

.titlePage {
	font-size: 1.25rem;
	font-weight: 700;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 2rem;
	margin-bottom: 2rem;
}

.titlePageGreen {
	font-size: 1.25rem;
	font-weight: 700;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 2rem;
	margin-bottom: 2rem;
	color: var(--color-dark-green)
}

.block {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	width: 15.9rem;
	height: 10.79rem;
	border-left: 0.59rem solid var(--color-price-type);
	font-size: 0.825rem;
	overflow: hidden;
	margin-bottom: 1.6rem;
	// padding-left: 2.1875rem;
	// padding-left: 1.0625rem;
	background-color: var(--color-background-header);
	border-radius: var(--md-raduis);
	&:nth-of-type(2) {
		margin-top: 1.5rem;
	}
}

.block:nth-of-type(3) {
	height: 10.79rem;
	border-left: 0.59rem solid var(--color-blue);
	.btnBlock {
		color: var(--color-blue);
	}
	.btnBlock:hover {
		background-color: var(--color-blue-hover);
	}
}

.block:nth-of-type(4) {
	//	display: flex;
	height: 7.7rem;
	border-left: 0.59rem solid var(--color-dark-green);
	.btnBlock {
		color: var(--color-dark-green);
	}
	.btnBlock:hover {
		background-color: var(--color-green-hover);
	}
}

.block:nth-of-type(5) {
	//	display: flex;
	border-left: 0.59rem solid var(--color-red);
	.btnBlock {
		color: var(--color-red);
	}
	.btnBlock:hover {
		background-color: var(--color-red-hover);
	}
}


// ======= когда вкладка кнопки активна =======
.btnBlockGreen {
	//серые
	display: flex;
	justify-content: center;
	align-items: center;
	width: 75%;
	height: 2.5rem;
	font-weight: 400;
	// margin-top: 0.5rem;
	background-color: var(--profile-hover-gray);
	border-radius: 0.5625rem;
	&:active {
		background-color: var(--profile-hover-gray);
	}
}
// синие
.block:nth-of-type(3) {
	.btnBlockGreen {
		background-color: var(--color-blue-hover);
		color: var(--color-blue-gray);
	}
	.btnBlockGreen:active {
		background-color: var(--profile-blue-hover);
	}
}
// зеленые
.block:nth-of-type(4) {
	.btnBlockGreen {
		background-color: var(--color-green-hover);
		color: var(--color-dark-green);
	}
	.btnBlockGreen:active {
		background-color: var(--color-green-hover);
	}
}
// красные
.block:nth-of-type(5) {
	.btnBlockGreen {
		background-color: var(--color-red-hover);
		color: var(--color-dark-red);
	}
	.btnBlockGreen:active {
		background-color: var(--color-red-hover);
	}
}
// =====================================

.btnBlock {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 75%;
	height: 2.5rem;
	font-weight: 400;
	margin-top: 0.5rem;
	border-radius: 0.5625rem;
	&:first-child {
		margin-top: 0rem;
	}
}

.btnBlock:last-of-type {
	border-bottom: none;
}

.btnBlock:hover {
	border-radius: 0.5625rem;
	background-color: var(--profile-hover-gray);
}

.btnBlock:active {
	// background-color: rgba(0, 0, 0, 0.8);
	.contentBlock {
		display: none;
	}

	// &:active:nth-of-type(1) {
	// 	background-color: rgba(0, 0, 0, 0.8);
	// }
	// &:active:nth-of-type(2) {
	// 	background-color: rgba(0, 0, 0, 0.8);
	// }
	// &:active:nth-of-type(3) {
	// 	background-color: rgba(0, 0, 0, 0.8);
	// }
}

.blocked {
	font-size: 0.8rem;
	color: var(--color-white);
	font-weight: normal;
}

.btnBlock:not(:active) {
	.blocked {
		display: none;
	}
}

.hrLine {
	width: 11.2rem;
	height: 0.0625rem;
	background-color: var(--color-line);
	margin: 1.9375rem 0rem;
}

.AdsBtn {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 11.25rem;
	height: 3rem;
	font-size: 0.75rem;
	font-weight: bold;
	margin-bottom: 2.5rem;
	color: var(--color-dark-red);
	border-radius: var(--md-raduis);
	&:hover {
		background-color: var(--color-red-hover);
	}
}

.AdsBtn:not(:active) {
	.blocked {
		display: none;
	}
}

.AdsBtn:active {
	background-color: rgba(0, 0, 0, 0.8);
	.contentBlock {
		display: none;
	}
}

.wrapperArrow {
	display: flex;
	flex-direction: column;
	justify-content: start;
	align-items: center;
	width: 100%;
	.textBlock {
		user-select: none;
		font-size: 0.875rem;
		font-weight: bold;
		color: var(--color-light-gray-five);
		margin: 1.5625rem 0rem;
	}
}

.imgArrow {
	width: 8.0625rem;
	height: 13.25rem;
	margin-left: 25rem;
}
