.wrapperRootDropDown {
	display: flex;
	align-items: flex-start;
	align-items: center;
	border: 1px solid #99a8b2;
	border-radius: 7px;
	padding-inline: 1px;
	box-shadow: 1px 1px 10px 0px rgba(0, 0, 0, 0.15);
}
.wrapperDropDown {
	display: flex;
	flex-direction: column;
	justify-content: center;
	text-align: center;
	align-items: center;
	cursor: pointer;
	position: relative;

	.btnSort {
		overflow: hidden;
		display: flex;
		justify-content: center;
		width: 10.2rem;
		height: 1.45rem;
		background: none;
		border-radius: 0.375rem;
		font-size: 0.65rem;
		font-family: inherit;
		color: inherit;
		margin: 0;
		padding-left: 0.125rem;
		padding-right: 0.3125rem;
		.wrapperBlock {
			background-color: var(--color-dark-blue);
			margin: 0.2rem 0.1rem;
			border-radius: 0.125rem;
			padding: 0.225rem;
			font-size: 0.65rem;
			color: var(--color-white);
			font-weight: 600;
		}
	}
	.btnSortTrue {
		overflow: hidden;
		display: flex;
		justify-content: flex-start;
		width: 10.2rem;
		height: 1.45rem;
		background: none;
		border-radius: 0.375rem;
		font-size: 0.75rem;
		font-family: inherit;
		color: inherit;
		margin: 0;
		padding: 5px;
		.wrapperBlock {
			background-color: var(--color-dark-blue);
			margin-right: 0.2rem;
			border-radius: 0.125rem;
			display: flex;
			justify-content: center;
			align-items: center;
			height: 100%;
			padding-inline: 0.3rem;
			font-size: 0.6rem;
			color: var(--color-white);
			font-weight: 500;
		}
	}
	ul::-webkit-scrollbar {
		display: none;
	}

	ul {
		list-style-type: none;
		padding: 0;
		background-color: var(--color-background-header);
		text-align: center;
		border-radius: 7px;
		border: 1px solid var(--color-light-gray);
		overflow: scroll;
		-ms-overflow-style: none; /* IE и Edge */
		scrollbar-width: none; /* Firefox */
		box-shadow: var(--shadow);
		position: absolute;
		z-index: 100;
		top: 2.1875rem;
		height: 9.375rem;
		box-shadow: 1px 1px 10px 0px rgba(0, 0, 0, 0.15);
		li {
			width: 10.2rem;
			font-size: 0.75rem;
			font-weight: 500;

			padding-top: 0.3125rem;
			padding-bottom: 0.3125rem;
			font-family: 'Roboto', sans-serif;
			border-bottom: 0.25px solid var(--color-bottom-list);
			&:last-of-type {
				border-bottom: none;
			}
			&:not(:first-child):hover {
				background-color: var(--color-dark-blue) !important;
				color: var(--color-white) !important;
			}
		}
		.clickedLi {
			background-color: var(--color-dark-blue);
		}
	}
}

.wrapperPolygon {
	position: absolute;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	right: 0;
	//margin-top: .005rem;
	width: 1.3rem;
	height: 1.1rem;
	// border-bottom: 1.5px solid #99a8b2;
	// border-right: 1.5px solid #99a8b2;
	// border-top: 1.5px solid #99a8b2;
	background-color: var(--color-background-header);
}
.wrapperBlockFlex {
	position: relative;
	left: -14%;
	display: flex;
	flex-direction: column;
	width: 0.625rem;
	height: 100%;
}

.block {
	width: 6.32px;
	height: 6.32px;
	transform: rotate(47deg);
	background-color: var(--color-background-header);
}

.polygonDropDown {
	position: relative;
	left: -20%;
	border-left: 0.25rem solid transparent;
	border-right: 0.25rem solid transparent;
	border-bottom: 0.375rem solid #99a8b2;
}
.polygonDropDownRotate {
	position: relative;
	left: -20%;
	border-left: 0.25rem solid transparent;
	border-right: 0.25rem solid transparent;
	border-top: 0.375rem solid #99a8b2;
	border-bottom: none;
}

.btnSort {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-left: 1.125rem;
	font-family: 'Roboto', sans-serif;
}

.reload_btn {
	background: none;

	display: flex;
	align-items: center;

	margin-left: 0.3125rem;
}

.reload_btn:active img {
	animation: rotate 0.25s linear;
}

@keyframes rotate {
	to {
		transform: rotate(-360deg);
	}
}
.imgIcon {
	width: 0.75rem;
	height: 0.75rem;
}
.firstLi {
	display: flex;
	justify-content: center;
}
.wrapperInput {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-top: 0.3125rem;
	margin-bottom: 0.3125rem;
	padding-top: 0.2125rem;
	padding-bottom: 0.2125rem;
	width: 8.925rem;
	border-radius: 0.4375rem;
	border: 1px solid var(--color-dark-blue);
	input {
		border-style: none;
		outline: none;
		font-family: 'Roboto', sans-serif;
		font-size: 0.75rem;
		font-weight: 400;
	}
	::-ms-input-placeholder {
		/* Edge 12 -18 */
		color: var(--color-light-gray);
	}
}

.img_search {
	width: 0.625rem;
	height: 0.8125rem;
	margin-right: 3.5%;
	margin-left: 3.5%;
}
li {
	position: relative;
}
.wrapperDoneIcon {
	display: block;
	position: absolute;
	background-color: var(--color-white);
	padding: 0.109rem;
	border-radius: 10rem;
	//top: 25%;
	left: 10%;
}
.wrapperDoneIconBLUE {
	display: block;
	position: absolute;
	background-color: var(--color-dark-blue);
	padding: 0.109rem;
	border-radius: 10rem;
	//top: 25%;
	left: 10%;
}
article {
	white-space: nowrap;
}
.wrapperNameCategories {
	display: flex;
	justify-content: center;
	width: 0s.875rem;
}
