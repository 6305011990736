.blockStatistic {
	display: block;
	margin-left: 0.625rem;
	.btnStat {
		border: var(--border-tgstat);
		border-radius: var(--l-raduis);
		width: 5.125rem;
		height: 1.25rem;
		display: flex;
		justify-content: space-evenly;
		align-items: center;
		align-content: center;
		flex-direction: row;
		font-weight: 600;
		color: var(--color-light-blue);
		font-size: 0.625rem;
	}
	.btnStat:active {
		color: var(--color-white);
		background-color: var(--color-light-blue);
	}
	.btnStat:active img {
		filter: brightness(0) invert(1);
	}
	.TgStatImg {
		display: block;
		width: 0.5625rem;
		height: 0.5625rem;
	}
}
