.wrapperGridDetailChannels {
	display: flex;
	justify-content: space-between;
	align-items: center;
	position: relative;
	flex-direction: column;

	margin-top: 1.875rem;
}
.wrapperLinkAndInfoAbout {
	display: flex;
}
.ImgBackground {
	display: block;
	margin-top: 1.25rem;
	width: 100vw;
	border-radius: 15%;
	img {
		padding-left: 6.875rem;
		padding-right: 6.875rem;
		width: 100%;
		object-fit: cover;
		height: 15.875rem;
		border-top-left-radius: 8.7%;
		border-top-right-radius: 8.7%;
	}
}

.wrapperCatalog {
	display: flex;
	width: 100%;
	padding-left: 6.875rem;
	padding-right: 6.875rem;
	justify-content: space-between;
	div {
		margin-left: 1rem;
	}
}
.wrapperChannelName {
	display: flex;
	justify-content: space-evenly;
	flex-wrap: wrap;
	margin-left: 6.875rem;
	margin-right: 6.875rem;
	background-color: var(--color-white-background);
	box-shadow: var(--shadow);
	margin-bottom: 2.5rem;
	border-bottom-left-radius: var(--md-raduis);
	border-bottom-right-radius: var(--md-raduis);
	padding-bottom: 6.25rem;

	.wrapperIconAndName {
		display: flex;

		.wrapperIcon {
			display: block;
			position: relative;
			top: -10%;
			margin-right: 1.875rem;
			img {
				object-fit: cover;
				border: 0.5rem solid var(--color-white);
				border-radius: 50%;
				width: 13.5rem;
				height: 13.5rem;
			}
		}
	}
	.wrapperStatistics {
		display: flex;

		justify-content: space-between;
		align-items: center;
		flex-direction: column;
	}

	.wrapperCountViews {
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;
		border-radius: var(--md-raduis);
		width: 11.4375rem;
		height: 3.625rem;
		box-shadow: var(--shadow);
		span {
			font-family: 'Roboto', sans-serif;
			font-size: 1.25rem;
			font-weight: 600;
			color: var(--color-light-gray-secondary);
		}
	}
}
.wrapperChannelNameAndStatistics {
	display: flex;
	flex-direction: column;
}

.StatisticsRoot {
	display: flex;
	align-items: flex-start;
	align-content: space-between;
	flex-direction: column;
	.wrapperDesc {
		display: block;
		.line {
			margin-top: 1.8125rem;
			border: 0.0625rem solid #e1e5e8;
			width: 31.9375rem;
			position: static;
		}
		> span {
			display: block;
			margin-top: 1.25rem;
			margin-bottom: 1.25rem;
			font-weight: 600;
			font-size: 1.25rem;
			color: var(--color-light-gray);
		}
	}
	.wrapperCategoryAndPosition {
		display: block;
		height: 1.5625rem;
		// margin-bottom: .625rem;
		.bordedElement {
			border: 0.1rem solid var(--color-light-gray);
			border-radius: 0.625rem;
			padding: 0.13rem 0.3125rem 0.13rem 0.4375rem;
			font-size: 0.625rem;
			font-weight: 600;
			&:first-of-type {
				margin-right: 0.3125rem;
			}
		}
	}

	.StatisticsNameChannel {
		font-family: 'Roboto', sans-serif;
		font-size: 2.625rem;
		font-weight: 600;
		color: var(--color-black);
	}
	.WrapperStatisticsMore {
		display: block;
		margin-top: 0.15rem;
	}
}
.blockStatisticsNameChannel {
	margin-top: 0.9375rem;
	margin-bottom: 0.625rem;
	//margin-bottom: -40%;
	//height: 18.75rem;
	//padding-bottom: 30%;
}

.wrapperTwoStatistics {
	display: flex;
	flex-direction: row;
	margin-top: 0.9375rem;
	margin-bottom: 1.6875rem;
	div {
		&:first-of-type {
			margin-right: 1.3125rem;
		}
	}
}

.wrapperStatisticsTitle {
	display: flex;
	justify-content: center;

	> .wrapperStatsTitle {
		height: 1.25rem;
		> span {
			height: 0.625rem;
			vertical-align: text-top;
			font-size: 1.25rem;
			font-weight: 700;
			color: var(--color-light-gray);
		}
	}

	margin-top: 1.25rem;
	margin-bottom: 0.8875rem;
}

.IconSize {
	width: 1.75rem;
	height: 1.375rem;
}

.btnSlideTop {
	width: 9.75rem;
	height: 2.8125rem;
	font-size: 1rem;
	border: 0.0625rem solid var(--color-light-gray);
	box-shadow: var(--shadow);
	border-radius: 0.9375rem;
	margin-left: 6.875rem;
	margin-bottom: 2.5rem;
	background: none;
}
