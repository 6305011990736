.wrapperProfileInner {
	display: flex;
	justify-content: flex-end;
	align-items: flex-start;
	padding-top: 1.25rem;
	padding-right: 1.25rem;
	width: 67.3rem;
	height: 40rem;
	margin: 1.25rem;
	margin-left: 3.3125rem;
	border-radius: 1.625rem;
	background-color: var(--color-background-black);
	> section {
		article {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			span {
				font-size: 1rem;
				font-weight: 600;
				color: var(--color-light-gray);
			}
			div {
				.imgCopyLink {
					width: .75rem;
					height: .875rem;
				}
			}
			.divCopyLink {
				display: flex;
				justify-content: center;
				align-items: center;
				width: 13rem;
				height: 2rem;
				border: 0.125rem solid var(--color-blue);
				border-radius: 0.55rem;
				overflow: hidden;
				margin-top: 1rem;
				margin-bottom: 1.6rem;
				span {
					font-weight: bold;
					margin-left: 0.9375rem;
					color: var(--color-blue);
					margin-right: 20%;
				}
				&:hover {
					background-color: var(--color-light-blue-copy);
				}
			}
			.divCopied {
				color: var(--color-white);
				background-color: var(--color-blue);
				display: flex;
				justify-content: center;
				align-items: center;
				width: 13rem;
				height: 2rem;
				border-radius: 0.55rem;
				margin-top: 1rem;
				margin-bottom: 1.6rem;
				transition: background-color 1s ease-in-out; 
				span {
					font-weight: bold;
					margin-left: 0rem;
					color: var(--color-white);
				}
				.imgCopyLink {
					display: none;
				}
			}
		}
	}
}
