.switchLangWrapper {
	position: relative;
	cursor: pointer;
	z-index: 1000000;
}
.imgEarth {
	// width: .9375rem;
	// height: .9375rem;
	// svg {
	// 	path {
	// 		stroke: #fff !important;
	// 	}
	// }
}
.lableLang {
	position: absolute;
	top: 60%;
	left: 50%;
	height: 0.65rem;
	// display: flex;
	// align-items: center;
	// justify-content: center;
	border-radius: 0.2rem;
	background-color: var(--color-blue);
	color: var(--color-white);
	font-size: 0.5rem;
	padding-inline: 0.15rem;
	font-weight: 700;
}
.DropDown2 {
	display: none;
	ul {
		display: none;
		li {
			display: none;
		}
	}
}
.dropDown {
	position: absolute;
	display: flex;
	flex-direction: column;

	overflow: hidden;
	top: 150%;
	left: -40%;
	width: 2rem;
	height: 2.7rem;
	border: 0.0625rem solid var(--color-light-gray);
	border-radius: var(--l-raduis);
	background-color: var(--color-background-header);
	ul {
		display: flex;
		flex-direction: column;
		justify-content: space-evenly;
		height: 100%;
		width: 100%;
		li {
			display: flex;
			justify-content: center;
			align-items: center;
			width: 100%;
			height: 100%;
		}
	}

	font-size: 0.625rem;
	color: var(--color-light-gray);
	input {
		-webkit-appearance: none;
		-moz-appearance: none;
		appearance: none;
	}
	// .wrapperBlue {
	// 	background-color: var(--color-blue);
	// 	color: var(--color-white);
	// }
}

.wrapperBlue {
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: var(--color-blue);
	width: 100%;
	color: var(--color-white);
}
