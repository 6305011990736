body {
	--color-light-blue: #00a5e9;
	--color-background-header: #fff;
	--color-light-yellow: #ffd643;
	--color-light-gray: #99a8b2;
	--color-light-gray-five: #99a8b2;
	--color-light-gray-secondary: #99a8b3;
	--color-gray: #99a8b24d;
	--color-light-green: #8ae035;
	--color-bottom-list: rgba(153, 168, 178, 0.3);
	--color-white: #ffff;
	--color-white-background: #ffff;
	--color-background-root: #fafbfe;
	--color-dark-blue: #00a5e9;
	--color-dark-yellow: #ffc700;
	--color-dark-gray: #646464;
	--color-dark-green: #5fbd03;
	--color-black: #000;
	--color-price-default: #444444;
	--color-switch: #212121;
	--color-lighter-gray: #c4ced6;
	--color-lighter-green: #6dd664;
	--color-popupExcl: rgba(0, 0, 0, 0.7);
	--color-blue-header: rgba(5, 143, 249, 0.1);
	--color-yellow-header: rgba(255, 199, 0, 0.1);
	--color-blue: #058ff9;
	--color-blue-click: #28a2ff;
	--color-yellow-new: #ffc700;
	--color-yellow-new-2: #ffd542;
	--color-black-gray: #262629;

	--color-yellow: #fcbc49;
	--color-price-type: #d9d9d9;
	--md-raduis: 11px;
	--color-gray-third: #a5a5a5;
	--color-red: #ff6363;
	--color-light-red: #ff7777;
	--color-light-red-two: #ff8c8c;
	--color-background-black: #131314da;
	--l-raduis: 7px;
	--color-quotes: #f1f8fd;
	--color-quotes-two: #f1f8fd;
	--shadow: 0 0 10px 1px rgba(0, 0, 0, 0.149);
	--shadow-yellow: 0 0 10px 1px #ffc700;
	--shadow-style: 0 0 10px 1px rgba(0, 0, 0, 0.149);
	--border-tgstat: 2px solid #21caff;
	--background-panel: rgba(0, 0, 0, 0.288);
	--color-blur: rgba(0, 0, 0, 0.15);

	--color-green-hover: rgba(95, 189, 3, 0.1);
	--color-blue-hover: rgba(5, 143, 249, 0.1);
	--color-red-hover: rgba(255, 99, 99, 0.1);
	--profile-hover-gray: rgba(153, 168, 178, 0.1);
	--color-line: rgba(217, 217, 217, 0.1);
	--color-dark-red: #f90505;
	--color-light-gray-auth: #bcbcbc;
	--color-light-blue-copy: rgba(5, 143, 249, 0.1);
	--color-gray-filt: rgba(153, 168, 178, 0.5);
	--color-darkness: #f1f1f1;
}
body.dark {
	--color-background-header: #1c1c1c;
	--color-light-blue: #00a5e9;
	--color-light-yellow: #ffd643;
	--color-light-gray: #99a8b2;
	--color-light-gray-five: #292929;
	--color-light-gray-secondary: #99a8b3;
	--color-gray: #99a8b24d;
	--color-light-green: #8ae035;
	--color-bottom-list: rgba(153, 168, 178, 0.3);

	--color-white: #ffff;

	--color-background-root: #262629;
	--color-white-background: #3f3f3f;
	--color-quotes: #3f3f3f;
	--color-quotes-two: #2b2b2b7a;
	--color-dark-blue: #00a5e9;
	--color-dark-yellow: #ffd643;
	--color-dark-gray: #646464;
	--color-dark-green: #5fbd03;
	--color-black: #fff;
	--color-price-default: #fff;
	--color-price-type: #d9d9d9;
	--md-raduis: 11px;
	--color-gray-third: #a5a5a5;
	--color-red: #ff6363;
	--color-background-black: #131314da;
	--l-raduis: 7px;
	--color-black-gray: #e5e5ea;
	--color-popupExcl: rgba(255, 255, 255, 0.884);

	--color-darkness: #27272a;

	--shadow: 0 0 10px 1px rgba(0, 0, 0, 0.149);
	--shadow-yellow: 0 0 10px 1px #ffc700;
	--shadow-style: 0 0 10px 1px rgba(0, 0, 0, 0.149);
	--background-panel: rgba(0, 0, 0, 0.384);
}
