.wrapperEditor {
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 100000;
	pointer-events: none;
	margin: 1.25rem;
	user-select: none;
}

.wrapperEditorText {
	display: flex;
	justify-content: center;
	align-items: center;
	position: absolute;
	z-index: 100001;
	font-size: 1.125rem;
	color: var(--color-white);
	font-weight: bold;
	background-color: var(--background-panel);
	backdrop-filter: blur(0.0625rem);
	border-radius: var(--md-raduis);
	width: 67.3rem;
	height: 39.4rem;
	margin: 1.25rem;
}

.editor {
	margin: 1.6rem 0rem;
	margin-left: 3.24rem;
	display: flex;
	flex-direction: column;

	width: 67.3rem;

	color: var(--color-light-gray);
	font-size: 0.75rem;
	font-weight: 500; // medium = 500

	border-radius: var(--md-raduis);
	box-shadow: var(--shadow-style);
	background-color: var(--color-background-header);

	padding: 0rem 3.625rem;

	.paramsHeader {
		display: block;
		font-size: 1rem;
		margin: 1.5rem 0rem;
	}

	span {
		margin-bottom: 0;
	}
	
	button:active {
		filter: brightness(1.09);
	}
}

.wrapperBtnDelete {
	display: flex;
	justify-content: end;
}

.costAdvertising:nth-of-type(2) .paramsHeader {
	margin-left: 1.875rem;
}

.salesBlock .paramsHeader {
	color: var(--color-dark-yellow);
}

.headerEditor {
	width: 67.3rem;
	height: 3.65rem;
	margin-left: -3.625rem;
	margin-right: -1.875rem;
	padding-left: 1rem;
	padding-right: 2.4rem;
	display: flex;
	justify-content: space-between;

	//	border-bottom: 0.0625rem solid var(--color-price-type);
	border-bottom-left-radius: 0.625rem;
	border-bottom-right-radius: 0.625rem;
	border-top-right-radius: 0.625rem;
	border-top-left-radius: 0.625rem;
	box-shadow: 0rem 0.225rem 0.3375rem 0.0875rem rgba(0, 0, 0, 0.158);
	.channelName {
		font-size: 1.4rem;
		font-weight: bold;
		color: var(--color-black);
	}
}

.imgExclamation {
	display: flex;
	justify-content: center;
	align-items: center;

	margin-left: 30rem;
	margin-top: 0.9rem;

	.popupExcl {
		width: 7.5625rem;
		height: 1.9375rem;
		font-size: 0.5rem;
		border-radius: 0.125rem;
		box-shadow: var(--shadow-style);
		color: var(--color-popupExcl);
		// background-color: var(--color-white-background);
		padding: 0.25rem;
		position: relative;
	}

	img {
		margin-left: 0.3rem;

		width: 0.75rem;
		height: 0.75rem;
	}
}

.editorContent {
	border-bottom: 0.0625rem solid var(--color-line);
	border-top: 0.0625rem solid var(--color-line);
	display: flex;
	flex-direction: row;
	justify-content: space-evenly;
	height: 16.8125rem;
	height: 15.6rem;
	width: 60.5rem;
}

.paramsChannel {
	width: 16.875rem;
}

.logoEditor {
	display: flex;
	flex-direction: row;
	align-items: center;
	border-radius: 50%;
	img {
		border-radius: 50%;
		border: 0.125rem solid var(--color-white);
		width: 2.6rem;
		height: 2.6rem;

		margin: 0.9375rem;
	}
}

// ===== BUTTONS ====== //

.headerBtn {
	display: flex;
	justify-content: space-between;
	align-items: center;

	button {
		width: 6.8rem;
		height: 1.8rem;
		border-radius: var(--l-raduis);
		font-size: 0.6rem;
	}
}
// кнопка "Опубликовать"
.btnPublish {
	background-color: var(--color-light-gray);
	color: var(--color-white);
	font-weight: 900; // black = 900
	font-family: inherit;
	margin-right: 1.25rem;
	transition: background-color 0.4s ease;
	/* &:hover {
		background-color: var(--color-dark-green2);
		color: var(--color-dark-green);
		border: 0.0625rem solid var(--color-dark-green);
	} */
}
.btnPublishGreen {
	background-color: var(--color-dark-green);
	color: var(--color-white);
	font-weight: 900; // black = 900
	font-family: inherit;
	margin-right: 1.25rem;
	transition: background-color 0.4s ease;
	/* &:hover {
		background-color: var(--color-dark-green2);
		color: var(--color-dark-green);
		border: 0.0625rem solid var(--color-dark-green);
	} */
}
.btnIsModeration {
	display: none;
}
// статус на модерации
.isModerate {
	margin-left: 1.25rem;
	border: 0.0625rem solid var(--color-blue);
	color: var(--color-blue);
}
// статус отклонен
.isKicked {
	margin-left: 1.25rem;
	border: 0.0625rem solid var(--color-red);
	color: var(--color-red);
}
// статус "Черновик"
.btnDraft {
	margin-left: 1.25rem;
	border: 0.0625rem solid var(--color-red);
	color: var(--color-red);
	// transition: background-color 0.5s ease;
}
// кнопка "На паузу"
.btnOnPause {
	background-color: var(--color-dark-yellow);
	color: var(--color-white);
	font-weight: 900;
	font-family: inherit;
	margin-right: 1.25rem;
	transition: background-color 0.3s ease;
	&:active {
		background-color: var(--color-light-yellow);
		filter: none;
	}
}
// статус" Опубликован"
.btnIsPublish {
	margin-left: 1.25rem;
	border: 0.0625rem solid var(--color-dark-green);
	color: var(--color-dark-green);
	transition: background-color 0.5s ease;
}
// Статус "На паузе"
.btnIsPause {
	margin-left: 1.25rem;
	border: 0.0625rem solid var(--color-dark-yellow);
	color: var(--color-dark-yellow);
	transition: background-color 0.5s ease;
}

.btnDelete {
	width: 6.8rem;
	height: 1.8rem;
	color: var(--color-white);
	background-color: var(--color-red);
	border-radius: var(--l-raduis);

	font-size: 0.625rem;
	font-family: inherit;
	font-weight: 900;

	margin: 1.5rem 0rem;
}

.btnDelete:active {
	background-color: var(--color-light-red);
}

.btnDownload {
	width: 10.2rem;
	height: 1.4375rem;

	background: none;

	border-radius: var(--l-raduis);
	border: 0.0625rem solid var(--color-light-gray);
	box-shadow: var(--shadow-light);

	font-size: 0.625rem;
	font-family: inherit;
	color: inherit;

	display: flex;
	align-items: center;
	justify-content: center;
}

.reloadBtn {
	background: none;

	display: flex;
	align-items: center;

	margin-left: 0.3125rem;
}

.reloadBtn:active img {
	animation: rotate 0.25s linear;
}

@keyframes rotate {
	to {
		transform: rotate(-360deg);
	}
}
.imgIcon {
	width: 0.625rem;
	height: 0.625rem;
}

// ====== image ====== //

.previewCover {
	// width: 1.1rem;
	height: 1.1rem;
	margin-left: 0.1875rem;
}

// ============ //

.description {
	width: 10.625rem;
	height: 3.3125rem;
	border-radius: var(--l-raduis);
	border: 0.0625rem solid var(--color-light-gray);
	color: inherit;
	font-size: 0.625rem;
	resize: none;
	box-shadow: var(--shadow-light);
}

.item {
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-direction: column;
	margin-bottom: 1.2rem;

	&:first-of-type {
		margin-bottom: 1rem;
	}

	&:last-of-type {
		align-items: flex-start;
	}
}
.costAdvertising {
	// margin-left: 3.125rem;
}
.itemAdv {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin: 0.7rem 0rem;
	padding-bottom: 0.7rem;
	/* border-bottom: 0.0625rem solid var(--color-price-type); */
}

.costAdvertising:last-child {
	.itemAdv {
		border-bottom: none;
	}
}
.noBorderBottom .itemAdv {
	border-bottom: none;
}

// .noBorderBottom {
// 	.itemAdv {
// 		border-bottom: none;
// 	}
// }

// ======= Block 2 costAdvertising ======= //
.labelInputRed {
	position: relative;
	border-right: 0.0625rem solid var(--color-light-gray);
	border: 0.0625rem solid var(--color-red);
	border-left: none;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	overflow: hidden;

	padding-right: 0.5rem;
	&:last-of-type {
		border: 0.0625rem solid var(--color-light-gray);
		padding-right: 0.25rem;
		border-left: none;
		border-top-right-radius: 0.625rem;
		border-bottom-right-radius: 0.625rem;
	}
}
.labelInput {
	position: relative;
	border-right: 0.0625rem solid var(--color-light-gray);
	border: 0.0625rem solid var(--color-light-gray);
	border-left: none;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	overflow: hidden;

	padding-right: 0.5rem;
	&:last-of-type {
		border: 0.0625rem solid var(--color-light-gray);
		padding-right: 0.25rem;
		border-left: none;
		border-top-right-radius: 0.625rem;
		border-bottom-right-radius: 0.625rem;
	}
	img {
		width: 1rem;
		height: 1rem;
	}
}
.labelInputHot {
	position: relative;
	border-right: 0.0625rem solid var(--color-light-gray);
	//border: 0.0625rem solid var(--color-light-gray);
	border-left: none;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	padding-right: 0.5rem;
	overflow: hidden;
	&:last-of-type {
		//border: 0.0625rem solid var(--color-light-gray);
		padding-right: 0.25rem;
		border-right: none;
		//	border-top-right-radius: 0.625rem;
		//	border-bottom-right-radius: 0.625rem;
	}
}
.labelInputHotRedEn {
	position: relative;
	border-right: 0.0625rem solid var(--color-red);
	//border: 0.0625rem solid var(--color-light-gray);
	border-left: none;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	padding-right: 0.5rem;
	overflow: hidden;
	&:last-of-type {
		padding-right: 0.25rem;
		border-right: none;
	}
}
.labelInputHotEn {
	position: relative;
	border-right: 0.0625rem solid var(--color-dark-yellow);
	border-left: none;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	padding-right: 0.5rem;
	overflow: hidden;
	&:last-of-type {
		padding-right: 0.25rem;
		border-right: none;
	}
}
.advertisingItem {
	display: flex;
	align-items: center;

	width: 22rem;
	height: 2.3rem;

	border-radius: 0.4375rem;
	box-shadow: var(--shadow);
	background-color: var(--color-background-header);
	margin-right: 2.25rem;
}

.valute {
	width: 1rem;
	height: 1rem;
}

.btnCost {
	background-color: var(--color-light-gray);
	font-size: 0.75rem;
	color: var(--color-white);
	font-weight: 900;

	border-bottom-right-radius: 0.2875rem;
	border-top-right-radius: 0.2875rem;
	width: 6.5375rem;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}
// =============
.btnSale {
	font-size: 0.75rem;
	width: 4.375rem;
	border-right: 0.0625rem solid var(--color-light-gray);
	height: 100%;
	cursor: pointer;
	border: 0.0625rem solid var(--color-red);
	border-top-left-radius: 0.4375rem;
	border-bottom-left-radius: 0.4375rem;
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
}
.btnSaleDefault {
	font-size: 0.75rem;
	width: 4.375rem;
	border-right: 0.0625rem solid var(--color-light-gray);
	height: 100%;
	cursor: pointer;
	border: 0.0625rem solid var(--color-light-gray);
	border-top-left-radius: 0.4375rem;
	border-bottom-left-radius: 0.4375rem;
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
}

.wrapperTime {
	margin-top: 1.25rem;
	list-style-type: none;
	padding: 0;
	background-color: var(--color-background-header);
	text-align: center;
	border-radius: 0.4375rem;

	border: 0.0625rem solid var(--color-light-gray);
	overflow: hidden;
	box-shadow: var(--shadow);
	position: absolute;
	z-index: 1;
	top: 0.7rem;
}

.wrapperTime li {
	width: 4.38rem;
	font-size: 0.625rem;
	font-weight: 500;

	padding-top: 0.3rem;
	padding-bottom: 0.3rem;
	font-family: 'Roboto', sans-serif;
	border-bottom: 0.0625rem solid var(--color-bottom-list);
}

.wrapperTime li:last-of-type {
	border-bottom: none;
}
.liTime {
}

.clickedLi {
	background-color: var(--color-dark-blue);
}
.polygonDropDown {
	margin-top: 0.125rem;
	margin-left: 0.4rem;
	width: 0;
	height: 0;
	border-left: 0.25rem solid transparent;
	border-right: 0.25rem solid transparent;
	border-bottom: 0.375rem solid #99a8b2;
	background: none;
}
.polygonDropDownRotate {
	margin-top: 0.125rem;
	margin-left: 0.4rem;
	width: 0;
	height: 0;
	border-left: 0.25rem solid transparent;
	border-right: 0.25rem solid transparent;
	border-top: 0.375rem solid #99a8b2;
	border-bottom: none;
	background: none;
}

// ==================
.btnSort {
	width: 12.75rem;
	height: 1.75rem;

	background: none;

	border-radius: 0.4375rem;
	border: 0.0625rem solid var(--color-light-gray);

	font-size: 0.75rem;
	font-family: inherit;
	color: inherit;

	padding: 0.3125rem 0.9375rem 0.3125rem 3.125rem;
}

.inputCost {
	font-size: 0.75rem;
	margin-left: 0.75rem;
	margin-right: 0.0725rem;
	font-size: 0.75rem;

	color: var(--color-light-gray);
}

.priceType {
	width: 4.375rem;
	border-left: 0.0625rem solid var(--color-light-gray);
	height: 100%;
}

.number {
	font-size: 0.875rem;
	font-weight: bold;
	margin-right: 1.0625rem;
}

// ======= DropDown block2 ========= //

.dropDownWrapper {
	select {
		display: flex;
		align-items: flex-start;
		align-items: center;
	}
}

// ======= Sales Block 3 ======= //

.salesBlock {
}

.salesContent {
	&:last-child {
		.horizontalLine {
			height: 0;
		}
	}
}

.itemSale {
	display: flex;
	align-items: center;
	justify-content: space-between;

	width: 32.8125rem;
	height: 2.3rem;

	border-radius: 0.4375rem;
	border: 0.0625rem solid var(--color-light-gray);
	box-shadow: var(--shadow);
	box-sizing: border-box;

	margin: 0.7rem 0rem;
	overflow: hidden;
}

.itemSaleHot {
	display: flex;
	align-items: center;
	justify-content: space-between;

	width: 32.8125rem;
	height: 2.25rem;

	border-radius: 0.4375rem;
	border: 0.0625rem solid var(--color-dark-yellow);
	box-shadow: var(--shadow);

	margin: 0.7rem 0rem;
	overflow: hidden;
}

.itemSaleRed {
	display: flex;
	align-items: center;
	justify-content: space-between;

	width: 32.8125rem;
	height: 2.1875rem;

	border-radius: 0.4375rem;
	border: 0.0625rem solid var(--color-red);
	box-shadow: var(--shadow);

	margin: 0.7rem 0rem;
	overflow: hidden;
}

.horizontalLine {
	height: 0.05rem;
	width: 100%;
	//background-color: var(--color-price-type);
}

.btnStart {
	background-color: var(--color-light-gray);
	font-size: 0.75rem;
	font-weight: 900;
	color: var(--color-white);
	width: 6.9375rem;
	height: 100%;

	display: flex;
	align-items: center;
	justify-content: center;
}
.btnStartRed {
	background-color: var(--color-red);
	font-size: 0.75rem;
	font-weight: 900;
	color: var(--color-white);
	width: 6.9375rem;
	height: 100%;

	display: flex;
	align-items: center;
	justify-content: center;
}
.btnStartYellow {
	background-color: var(--color-dark-yellow);
	font-size: 0.75rem;
	font-weight: 900;
	color: var(--color-white);
	width: 6.9375rem;
	height: 100%;

	display: flex;
	align-items: center;
	justify-content: center;
	&:hover {
		background-color: var(--color-yellow-new-2);
	}
}
.btnStart:active {
	background-color: var(--color-light-gray);
	// filter: brightness(1);
}

.saleElement {
	border-left: 0.0625rem solid var(--color-light-gray);
	height: 100%;
	width: 4rem;

	display: flex;
	align-items: center;
	text-align: center;
	justify-content: flex-start;
	textarea,
	input {
		color: var(--color-light-gray);
		width: 2.8rem;
		font-size: 0.525rem;
	}
}
.saleElementEn {
	border-left: 0.0625rem solid var(--color-dark-yellow);
	height: 100%;
	width: 4rem;

	display: flex;
	align-items: center;
	text-align: center;
	justify-content: flex-start;
	textarea,
	input {
		color: var(--color-light-gray);
		width: 2.8rem;
		font-size: 0.525rem;
	}
}
.saleElementEnRed {
	border-left: 0.0625rem solid var(--color-red);
	height: 100%;
	width: 4rem;

	display: flex;
	align-items: center;
	text-align: center;
	justify-content: flex-start;
	textarea,
	input {
		color: var(--color-light-gray);
		width: 2.8rem;
		font-size: 0.525rem;
	}
}

.itemSale .saleElement:nth-of-type(1) {
	color: var(--color-dark-green);
	font-weight: bold;
	font-size: 0.625rem;
	width: 4rem;
	padding: 0.7rem;
}

.numWrapper {
}
.wrapperDisplayBtn {
	display: block;
	width: 100%;
	height: 100%;

	img {
		margin: 0;
		width: 1.8rem;
		height: 1.6rem;
		border: none;
		border-radius: none;
	}
}
.wrapperDisplayBtnRotate {
	display: block;
	height: 100%;
	width: 100%;
	img {
		margin: 0;
		height: 1.8rem;
		width: 1.6rem;
		border: none;
		border-radius: none;
	}
}
.wrapperForm {
	display: flex;
	justify-content: flex-end;
	flex-direction: row-reverse;
	width: 21.25rem;
	input {
		width: 0.7rem;
		height: 0.7rem;
		margin-right: 0.625rem;
	}
}

.forms {
	display: flex;
	width: 21.875rem;
	height: 9.375rem;
	flex-direction: column;
	justify-content: space-around;
	font-size: 0.719rem;
	span {
		margin-bottom: 0.4rem;
	}
	.customCheckbox {
		display: flex;
		align-items: center;
		cursor: pointer;
		// input.checked {
		// 	background-color: none;
		// 	background: none;
		// 	position: relative;
		// }
		// input.checked::before {
		// 	content: '2714';
		// 	background: none;
		// 	background-color: none;
		// 	font-size: 1.5em;
		// 	color: #fff;
		// 	position: absolute;
		// 	right: 1px;
		// 	top: -5px;
		// }
	}

	/* .checkboxInput {
		display: none;
	} */

	.checkboxSpan {
		display: inline-block;
		width: 0.7rem;
		height: 0.7rem;
		border: 0.0625rem solid var(--color-blue);
		background-color: var(--color-background-header);
		border-radius: 0.125rem;
		margin-right: 0.625rem;
		position: relative;
		cursor: pointer;
		margin-bottom: 0;
	}

	.checkboxInput:checked + .checkboxSpan {
		background-color: var(--color-blue);
	}

	.checkboxInput:checked + .checkboxSpan::after {
		content: '';
		position: absolute;
		top: 50%;
		left: 50%;
		width: 0.3rem;
		height: 0.6rem;
		border: solid #fff;
		border-width: 0 0.125rem 0.125rem 0;
		transform: translate(-50%, -50%) rotate(45deg);
	}
}
.descEditor {
	display: flex;
	justify-content: center;
	flex-direction: column;
	//width: 31.25rem;
	// height: 11rem;
	span {
		margin-bottom: 0.6rem;
	}
	.descriptionOne {
		margin-bottom: 0.9rem;
		padding: 0.45rem;
		width: 23.09rem;
		height: 6.25rem;
		border-radius: var(--l-raduis);
		border: 0.0625rem solid var(--color-light-gray);
		color: inherit;
		font-size: 0.625rem;
		resize: none;
		box-shadow: var(--shadow-light);
	}
}
.wrapperNewBlock {
	display: flex;
	justify-content: space-between;
	// margin-top: 0.625rem;
	margin-top: 1.5rem;
}
.paramsChannelOne {
	// width: 13rem;
	display: flex;
	justify-content: flex-start;
	.item {
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		text-align: start;
		align-items: flex-start;
	}
	span {
		margin-bottom: 0.6rem;
	}
}
.underline {
	height: 0.05rem;
	background-color: var(--color-line);
	width: 17.5rem;
	margin: 0.4rem 0rem;
}
.inderlineFirst {
	height: 0.05rem;
	background-color: var(--color-line);
	width: 9.2rem;
	margin-bottom: 0.85rem;
}
.titleEditorItems {
	font-size: 0.8125rem;
}

.blurInput {
	position: absolute;
	width: 100%;
	height: 100%;
	z-index: 100001;
	background-color: var(--color-blur);
	backdrop-filter: blur(0.0625rem);
	margin-left: .2rem;
	user-select: none;
}

.blurNone {
	display: none;
}

.blurInputCost {
	user-select: none;
	pointer-events: none;
	font-size: 0.75rem;
	margin-left: 0.75rem;
	margin-right: 0.0725rem;
	font-size: 0.75rem;

	color: var(--color-light-gray);
}