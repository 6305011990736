.wrapperGrid {
	display: flex;
	justify-content: space-between;
	flex-direction: column;
	width: 72vw;
	margin-left: 3.3rem;
	margin-bottom: 1.25rem;
	.wrapperHeaderGrid {
		display: flex;
		z-index: 1;
		justify-content: space-between;
		align-items: start;
		width: 100%;
	}
	.GridChannelsComponentsWrapper {
		display: flex;
		justify-content: flex-start;
		flex-wrap: wrap;
	}
}

.notFoundImg {
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin-top: 2.5rem;
	gap: 1.25rem;
	> img {
		width: 24rem;
		height: 16rem;
	}
	> p {
		color: rgba(5, 143, 249, 1);
		font-family: 'Roboto', sans-serif;
		font-weight: 700;
		font-size: 1.8rem;
		text-align: center;
		white-space: normal;
		line-height: 2.25rem;
		width: 16.5rem;
	}
}
