.wrapperStatistics {
	display: flex;
	width: 21.875rem;
	justify-content: space-between;
	flex-wrap: nowrap;
	div {
		display: flex;
		flex-direction: column;

		div {
			display: flex;
			justify-content: flex-start;
			flex-direction: row;

			span {
				font-size: 1rem;
				strong {
					font-weight: 600;
				}
			}
		}
	}
}
.imageWrapper {
	margin-right: 0.3125rem;
	width: 0.9375rem;
	height: 0.9375rem;
}

.wrapperOneStatistics {
	margin-top: 0.8375rem;
}
