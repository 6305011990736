.wrapperCopyLink {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2.5rem;
    height: 2rem;
    cursor: pointer;
    .imgCopyLink {
        width: .75rem;
        height: .875rem;
    }
}
// .wrapperCopyLink:active {
//     background-color: var(--color-blue);
//     filter: brightness(1);
// }
